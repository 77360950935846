$window-inner-height: var(--window-inner-height);

.images-grid {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: start;
  align-items: start;

  .image-item {
    max-width: 10%;

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
}